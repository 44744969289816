<template>
  <div class="recharge" :style="{ '--buttonColor': '#008EA7' }">
    <Navbar :title="$t('newAdd.Recharge')" />
    <div class="recharge_amount">
      <div class="title">{{ $t("newAdd.RechargeAmount") }}:&nbsp;</div>
      <div class="value">{{ $money(payData.money, false) }}</div>
    </div>
    <div class="input_bar">
      <div class="title">{{ $t("newAdd.RechargeAmount") }}</div>
      <van-field center v-model="bankName" :placeholder="$t('rechargeAccountEnter')" />
    </div>
    <van-cell-group class="payInfo_bar">
      <div class="title">{{ $t("newAdd.bankName") }}:</div>
      <van-cell :title="payData.setting.name">
        <Btn @click="copy('name')" slot="default" color="var(--red)">
          {{ $t("newAdd.copy") }}
        </Btn>
      </van-cell>
      <div class="title">{{ $t("newAdd.payee") }}:</div>
      <van-cell :title="payData.setting.open">
        <Btn @click="copy('open')" slot="default" color="var(--red)">
          {{ $t("newAdd.copy") }}
        </Btn>
      </van-cell>
      <div class="title">{{ $t("newAdd.bankName") }}:</div>
      <van-cell :title="payData.setting.card">
        <Btn @click="copy('card')" slot="default" color="var(--red)">
          {{ $t("newAdd.copy") }}
        </Btn>
      </van-cell>
      <div class="step_wrap">
        <div class="step_title">{{ $t("newAdd.rechargeSteps") }}：</div>
        <div class="step_content">
          <div v-for="(item, index) in steps" :key="index">
            {{ index + 1 }}.{{ item }}
          </div>
        </div>
      </div>
    </van-cell-group>
    <div class="submit_btn">
      <Btn @click="confirm" slot="default" color="var(--red)" block>
        {{ $t("newAdd.completePayment") }}
      </Btn>
    </div>
    <!-- <div class="content-wrap">
      <div class="padding16">
        <div class="content white-back">
          <div class="payNum font12">
            {{ $t("newAdd.RechargeAmount") }}：
            <span class="color-red font22">{{ payData.money }}</span>
          </div>
          <div class="top6 flex between width100pre center-ver">
            <div class="font14 address-wrap">
              {{ $t("newAdd.bankName") }}:
              <span class="font12">{{ payData.setting.open }}</span>
            </div>
            <div class="copy" @click="copy('open')">
              {{ $t("newAdd.copy") }}
            </div>
          </div>
          <div class="top6 flex between width100pre center-ver">
            <div class="font14 address-wrap">
              {{ $t("newAdd.payee") }}:
              <span class="font12">{{ payData.setting.name }}</span>
            </div>
            <div class="copy" @click="copy('name')">
              {{ $t("newAdd.copy") }}
            </div>
          </div>
          <div class="top6 flex between width100pre center-ver">
            <div class="font14 address-wrap">
              {{ $t("newAdd.cardNum") }}:
              <span class="font12">{{ payData.setting.card }}</span>
            </div>
            <div class="copy" @click="copy('card')">
              {{ $t("newAdd.copy") }}
            </div>
          </div>
        </div>
        <div class="step-wrap top16">
          <div class="font14">{{ $t("newAdd.rechargeSteps") }}：</div>
          <div class="font12 line1 top10">
            <div v-for="(item, index) in steps" :key="index">
              {{ index + 1 }}.{{ item }}
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- <div class="fixed btn-wrap flex">
      <div class="left" style="width: 100%" @click="confirm">
        {{ $t("newAdd.completePayment") }}
      </div>
    </div> -->
  </div>
</template>

<script>
import {
  Icon,
  Image as VanImage,
  Toast,
  Field,
  CellGroup,
  Cell,
  Button as Btn,
} from "vant";
import download from "downloadjs";
import { RECHARGE_COMMIT } from "@/api";
import Navbar from "@/components/JNav";
export default {
  components: {
    [Icon.name]: Icon,
    [VanImage.name]: VanImage,
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    Btn,
    Toast,
    Navbar,
  },
  data() {
    return {
      payData: {},
      steps: [],
      bankName: "",
    };
  },
  methods: {
    downloadQr() {
      // download(this.payData.qrimg, "mycode.png", "image");
      this.$router.back();
    },
    copy(which) {
      this.$copyText(this.payData.setting[which]).then((e) => {
        Toast("Copy successfully");
      });
    },
    confirm() {
      Toast.loading({
        forbidClick: true,
        duration: 0,
      });
      RECHARGE_COMMIT({
        id: this.payData.id,
        money: this.payData.money,
        account: this.payData.account,
        pay_account: this.bankName,
        isbefore: 1,
      })
        .then((r) => {
          Toast.clear();
          if (r.data.ret === 3) {
            Toast.clear();
            // Toast
            this.$router.back();
            // window.location.href = r.data.data.url;
          } else {
            // Toast('System exception, please try again later')
            Toast(r.data.msg);
          }
        })
        .catch((e) => {
          Toast.clear();
          console.error(e);
        });
    },
  },
  created() {
    let payData = this.$route.params.payData;
    console.log(payData);
    if (payData) {
      this.payData = payData;
      this.$cookie.set("qrPayData", JSON.stringify(this.payData));
    } else {
      this.payData = JSON.parse(this.$cookie.get("qrPayData"));
    }
    this.steps = this.payData.step.split(/[0-9]./);
    for (let i = 0; i < this.steps.length; i++) {
      let item = this.steps[i];
      if (item == "") {
        this.steps.splice(i, 1);
      }
    }
  },
  mounted() { },
  destroyed() {

  },
};
</script>

<style lang="less" scoped>
@Color: var(--theme);
@wrapPadding: calc(12rem / 16);
@comRadius: 10px;
// @comBG: #07304a;
@comBG: var(--bg1);

.recharge {
  position: relative;
  font-family: "PingFang-Regular";
  height: 100%;
  min-height: 100vh;
  overflow: hidden;
  background: var(--bg);

  .title {
    color: var(--textColor);
    font-size: calc(12rem / 16);
    text-indent: 0.7em;
  }

  .recharge_amount {
    display: flex;
    margin: @wrapPadding;
    align-items: center;

    .value {
      color: #fea700;
      margin-left: calc(6rem / 16);
      font-size: calc(15rem / 16);
    }
  }

  .input_bar {
    margin: @comRadius;
    margin-top: calc(26rem / 16);

    /deep/ .van-field {
      background: var(--light);
      border-radius: 6px;
      border: 1px solid var(--light);
      margin-top: calc(6rem / 16);

      input::placeholder {
        color: var(--textColor);
        opacity: 0.6;
      }

      input {
        color: var(--textColor);
      }
    }
  }

  .payInfo_bar {
    background: @comBG;
    margin: @wrapPadding;
    border-radius: @comRadius;
    overflow: hidden;

    &::after {
      border: none;
    }

    .title {
      margin-top: calc(10rem / 16);
      margin-left: calc(10rem / 16);
    }

    .van-cell {
      background: @comBG;
      align-items: center;

      .van-cell__title {
        color: var(--textColor);
      }

      .van-button {
        height: calc(32rem / 16);
        border-radius: 6px;
      }
    }

    .step_wrap {
      margin: 16px;
      font-size: calc(12rem / 16);

      .step_title {
        color: var(--textColor);
      }

      .step_content {
        color: var(--red);

        >div {
          margin-top: calc(6rem / 16);
        }
      }
    }
  }

  .submit_btn {
    margin: @wrapPadding;
    border-radius: @comRadius;
    overflow: hidden;
    margin-top: calc(46rem / 16);
  }

  .van-nav-bar {
    background-color: #ff0000;

    /deep/ .van-nav-bar__content {
      background-color: #ff0000;
    }

    /deep/.van-nav-bar__title {
      color: var(--textColor);
    }

    button {
      background-color: #ff0000;
      border: none;
      color: var(--textColor);
      font-size: calc(10rem / 16);
    }

    .iconfont {
      font-size: calc(22rem / 16);
      color: var(--textColor);
    }
  }

  .content-wrap {
    width: 100vw;
    min-height: 100vh;
    // background-color: #f4f8f9;
    position: relative;
    overflow: hidden;

    .content {
      padding: 1.375rem 0.875rem;
    }
  }
}
</style>

<style scoped>
.top10 {
  margin-top: 0.625rem;
}

.top6 {
  margin-top: 0.375rem;
}

.top16 {
  margin-top: 1rem;
}

.padding16 {
  padding: 1rem;
}

.padding10 {
  padding: 0.625rem;
}

.white-back {
  background-color: #fff;
}

.font12 {
  font-size: 0.75rem;
}

.font14 {
  font-size: 0.875rem;
}

.font16 {
  font-size: 1rem;
}

.font18 {
  font-size: 1.125rem;
}

.font22 {
  font-size: 1.375rem;
}

.color-red {
  color: #ff0000;
}

.flex {
  display: flex;
}

.between {
  justify-content: space-between;
}

.width100pre {
  width: 100%;
}

.address-wrap {
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.center-ver {
  align-items: center;
}

.center-hor {
  justify-content: center;
}

.line1 {
  line-height: 1.1;
}

.fixed {
  position: fixed;
}

.btn-wrap {
  bottom: 0;
  width: 100vw;
}

.btn-wrap>.left,
.right {
  width: 50vw;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--textColor);
}

.btn-wrap>.left {
  background-color: var(--red);
}

.btn-wrap>.right {
  background-color: #dc1323;
}
</style>